import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import SearchResult from "../../components/SearchResult";
import { videoFromNode } from "../../data/videos";

import Layout from "../../layouts/default";

export const Head = () => (
  <title>MandarInput: Comprehensible Input related to sports</title>
);

export const SportsPage = ({ data }: PageProps<Queries.SportsVideosQuery>) => {
  const filteredVideos = data.allVideosYaml.edges.map((node) =>
    videoFromNode(node.node)
  );

  return (
    <Layout>
      <Row>
        <Col sm={12} lg={4}>
          <div className="card">
            <h2>Learn Mandarin with sports videos</h2>
            <p>
              If you&apos;re learning Mandarin it&apos;s useful to listen to
              videos on topics that you are interested in, and suit your
              purposes for learning.
            </p>
            <p>
              These videos all relate to sports and fitness of various kinds. We
              have <Link to="/">lots of other videos</Link> if you&apos;re
              interested in other topics.
            </p>
            <p>
              Welcome to our series of videos covering a range of different
              sports! We&apos;re here to help you learn Chinese as a second
              language. Our videos are perfect for Chinese learners of all
              levels, as they feature both everyday conversations and
              specialized vocabulary related to different sports.
            </p>
            <p>
              We&apos;ve got videos covering a wide range of sports, from
              basketball and soccer to martial arts and tennis. Learning Chinese
              with sports is a great way to practice your language skills in a
              fun and exciting way. By following the conversations and mastering
              the specialized vocabulary, you&apos;ll be able to take part in
              conversations about your favorite sports with native Chinese
              speakers.
            </p>
          </div>
        </Col>
        <Col sm={12} lg={8}>
          {filteredVideos.map(
            (video) =>
              video !== null && (
                <SearchResult
                  key={video.videoId}
                  video={video}
                  viewed={false}
                />
              )
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default SportsPage;

export const pageQuery = graphql`
  query SportsVideos {
    allVideosYaml(filter: { topics: { in: "sports" } }) {
      edges {
        node {
          slug
          hsk
          duration
          channel
          topics
          title
          videoId
          subtitles {
            chinese
            english
            pinyin
          }
          speechSpeed
          difficulty
        }
      }
    }
  }
`;
